import React from "react";

import HomeSmall from "./HomeSmall";
import HomeBig from "./HomeBig";

const Home = ({ lang, mediaLog }) => {
  if (!mediaLog.laptop) {
    return <HomeSmall lang={lang} phone={mediaLog.phone} />;
  }

  return <HomeBig lang={lang} />;
};

export default Home;
