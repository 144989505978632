const Langs = {
  ro: "ro",
  eng: "eng",
  it: "it"
}

export const LangFlags = {
  ro: {
    img: "/images/flag-ro.png",
    alt: "RO"
  },
  eng: {
    img: "/images/flag-uk.png",
    alt: "ENG"
  }
}

export const NextLang = {
  ro: "eng",
  eng: "ro",
  it: "ro"
}

export default Langs
