import React from "react";

import { textContactSection } from "../../../Texts.js";

import "./ContactSection.css";

// TODO: new phone no

const ContactSection = ({ lang }) => {
  const { telNo, fbLink, mailAdd } = textContactSection;

  return (
    <div className="contact-section-container">
      <ul>
        <li>
          <i className="fas fa-phone-square fa-lg"></i>
          <span className="iqua-text">
            <a
              href={"tel:" + telNo}
              className="iqua-text
                            iqua-text-hoverable"
            >
              {telNo}
            </a>
          </span>
        </li>
        <li>
          <i className="fas fa-envelope-square fa-lg"></i>
          <span className="iqua-text">
            <a
              href={"mailto:" + mailAdd}
              className="iqua-text
                            iqua-text-hoverable"
            >
              {mailAdd}
            </a>
          </span>
        </li>
        <li>
          <i className="fab fa-facebook-square fa-lg"></i>
          <span className="iqua-text">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={fbLink}
              className="iqua-text
                            iqua-text-hoverable"
            >
              iQUA apă curată
            </a>
          </span>
        </li>
      </ul>
    </div>
  );
};

export const ContactButtons = () => {
  const { telNo, fbLink, mailAdd } = textContactSection;

  return (
    <div className="contact-buttons-container">
      <a href={"tel:" + telNo}>
        <i className="fas fa-phone"></i>
      </a>
      <a href={"mailto:" + mailAdd}>
        <i className="fas fa-envelope"></i>
      </a>
      <a target="_blank" rel="noopener noreferrer" href={fbLink}>
        <i className="fab fa-facebook-f"></i>
      </a>
    </div>
  );
};

export default ContactSection;
