import React, { useState } from "react";

import "./MailCollector.css";

const MailCollector = props => {
  const [email, setEmail] = useState("");
  const [info, setInfo] = useState({});

  const handleChange = event => {
    setEmail(event.target.value);
  };

  const handleSubmit = () => {
    setInfo({ fetching: "..." });
    fetch(`https://iqua-mail-list.herokuapp.com/add?email=${email}`)
      .then(response => {
        response.json().then(data => {
          setInfo(data);
        });
      })
      .catch(error =>
        setInfo({
          error: {
            ro: "Nu s-a putut realiza conecţiunea la serverele noastre",
            eng: "Something went wrong while connecting to our servers",
          },
        })
      );
  };

  const fromInfo = (info, lang) => {
    let toRender = "";
    if (info === {}) {
      return toRender;
    }

    if (info.fetching) {
      toRender = (
        <span>
          <span style={{ fontWeight: "bold" }}>Fetching</span>...
        </span>
      );
    } else if (info.error) {
      toRender = (
        <span>
          <span style={{ color: "#c80815", fontWeight: "bold" }}>Error:</span>{" "}
          {info.error[lang]}
        </span>
      );
    } else if (info.response) {
      toRender = (
        <span>
          <span style={{ color: "#089404", fontWeight: "bold" }}>Success:</span>{" "}
          {info.response[lang]}
        </span>
      );
    }

    return toRender;
  };

  return (
    <div className="mail-collector">
      <p className="sub-text">
        {
          {
            ro:
              "Newsletter-ul de la iQUA iţi aduce informaţii despre sănătatea ta, dar si oferte speciale.",
            eng:
              "Our newsletter brings you info about your health as well as special offers.",
          }[props.lang]
        }
      </p>
      <p className="unsub-text">
        {
          {
            ro:
              "Vom trimite puţine mesaje, dar interesante. Oricum te poţi dezabona oricând.",
            eng:
              "We send few but interesting emails. You can unsubscribe at anytime.",
          }[props.lang]
        }
      </p>
      <div id="mail-submit">
        <input
          id="mail-input"
          type="text"
          placeholder="email"
          onChange={handleChange}
        />
        <button onClick={handleSubmit}>
          {{ ro: "Abonare", eng: "Subscribe" }[props.lang]}
          <i className="fas fa-paper-plane"></i>
        </button>
      </div>
      <p className="info-text">{fromInfo(info, props.lang)}</p>
    </div>
  );
};

export default MailCollector;
