import React from "react";
import { Link } from "react-router-dom";

// import NavSection from "../../subs/NavSection/NavSection"
import NewNavSection from "../../subs/NewNavSection/NewNavSection";
import LogoCircle from "../../subs/LogoCircle/LogoCircle";
import MailCollector from "../../subs/MailCollector/MailCollector";
import ContactSection from "../../subs/ContactSection/ContactSection";
import { PresBox } from "./HomeBig";

import {
  textPres1,
  textPres2,
  textPres3,
  textPres4,
  textPres5,
  textHomePage1,
  textHomePage2,
  textHomePage3,
} from "../../../Texts.js";

import "./HomeSmall.css";

const HomeSmall = ({ lang, phone }) => {
  return (
    <div className="home-small-container">
      <div className="top-container">
        <LogoCircle />
      </div>

      <NewNavSection
        mediaLog={{ phone: phone, tablet: !phone, laptop: false }}
        special="home"
        lang={lang}
        noLogo
      />

      <div className="mid-container">
        <div className="text-1">{textHomePage1[lang]}</div>
        <div className="description-text description-big">
          {textHomePage3[lang]}
        </div>

        <section className="presentation-section">
          <PresBox
            head={textPres1[lang].head}
            body={textPres1[lang].body}
            icon="fas fa-hdd"
          />
          <img src="/images/presentation1.jpg" alt="image_not_found" />
          <PresBox
            head={textPres2[lang].head}
            body={textPres2[lang].body}
            icon="fas fa-coins"
          />
          <PresBox
            head={textPres3[lang].head}
            body={textPres3[lang].body}
            icon="fas fa-hand-holding-water"
          />
          <PresBox
            head={textPres4[lang].head}
            body={textPres4[lang].body}
            icon="fas fa-pump-medical"
          />
          <PresBox
            head={textPres5[lang].head}
            body={textPres5[lang].body}
            icon="fas fa-bell"
          />
        </section>

        <div className="description-text text-3">
          {textHomePage2[lang]}
          {"\n"}
          <Link to="/profi">
            {
              {
                ro: "Click aici pentru mai multe informaţii",
                eng: "Click here to find out more",
              }[lang]
            }
          </Link>
        </div>
      </div>

      <ContactSection lang={lang} />
      <MailCollector lang={lang} />

      <div className="bottom-container"></div>
    </div>
  );
};

export default HomeSmall;
