import React from "react";
// import NavSection from "../../subs/NavSection/NavSection"
import NewNavSection from "../../subs/NewNavSection/NewNavSection";

import {
  textApaCurataTitle as title,
  textApaCurataContent as texts,
} from "../../../Texts.js";
import "./ApaCurata.css";

const ApaCurata = ({ lang, mediaLog }) => {
  return (
    <div className="apa-curata-container">
      <NewNavSection mediaLog={mediaLog} lang={lang} special={"apaCurata"} />

      <div
        className="iqua-text content-container"
        style={{ display: !mediaLog.laptop && "block" }}
      >
        <div
          className="left-container"
          style={{ backgroundImage: !mediaLog.laptop && "none" }}
        >
          <h1>{title[lang]}</h1>
        </div>

        <div className="right-container">
          {texts[lang].map((text, i) => (
            <div key={i} className="iqua-text-box">
              <span className="outline-text">{text.outline}</span>
              {text.description}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ApaCurata;
