import React from "react"
import { Link } from "react-router-dom"

import "./LogoCircle.css"

const LogoCircle = ({ size }) => (
  <Link to="/" className="logo-circle-container"
      style={{width: size, height: size}}>
    <img src="/images/logo-redesign-transparent.png" alt="iQUA" />
  </Link>
)

export default LogoCircle
