import React from "react";

import NewNavSection from "../../subs/NewNavSection/NewNavSection";
import LogoCirce from "../../subs/LogoCircle/LogoCircle";
import ContactSection from "../../subs/ContactSection/ContactSection";
import MailCollector from "../../subs/MailCollector/MailCollector";

import "./Contact.css";

const Contact = props => {
  return (
    <div className="contact-container">
      <NewNavSection
        mediaLog={props.mediaLog}
        lang={props.lang}
        special={"contact"}
        noLogo
      />

      <div className="content-container">
        <LogoCirce />
        <div className="sub">
          <ContactSection />
          <MailCollector lang={props.lang} />
        </div>
      </div>
    </div>
  );
};

export default Contact;
