import React from "react";

import "./ImageGallery.css";

const ImageGallery = ({
  imgs,
  enlarge,
  index,
  decrement,
  increment,
  smallImgClick,
}) => {
  let handleClick = event => {
    let clickedClass = event.target.className;
    if (clickedClass.includes("bttn-decrement")) {
      decrement();
    } else if (clickedClass.includes("bttn-increment")) {
      increment();
    } else {
      enlarge();
    }
  };

  return (
    <div className="image-gallery-container">
      <div
        className="image-big"
        style={{ backgroundImage: `url('${imgs[index]}')` }}
        onClick={handleClick}
      >
        <i className="fas fa-expand-alt fa-sm bttn-enlarge"></i>
        <i className="fas fa-chevron-left bttn-decrement"></i>
        <i className="fas fa-chevron-right bttn-increment"></i>
      </div>
      <div className="images-small">
        {imgs.map((image, i) => (
          <img
            src={image}
            key={i}
            alt="img_not_found"
            onClick={() => smallImgClick(image)}
            className={i === index ? "selected-image" : null}
          />
        ))}
      </div>
    </div>
  );
};

export default ImageGallery;
