import React from "react";
import { Link } from "react-router-dom";

// import NavSection from "../../subs/NavSection/NavSection"
import NewNavSection from "../../subs/NewNavSection/NewNavSection";
import LogoCircle from "../../subs/LogoCircle/LogoCircle";
import ContactSection from "../../subs/ContactSection/ContactSection";
import MailCollector from "../../subs/MailCollector/MailCollector";

import {
  textPres1,
  textPres2,
  textPres3,
  textPres4,
  textPres5,
  textHomePage1,
  textHomePage3,
  textHomePage2,
} from "../../../Texts";
import boldify from "../../../Boldify";

import "./HomeBig.css";

export const PresBox = props => {
  return (
    <div className="pres-box-container">
      <img src={props.image} alt="image_not_found" />
      <p className="head">
        {props.icon && <i className={props.icon}></i>}
        {props.head}
      </p>
      <p className="body">{boldify(props.body)}</p>
    </div>
  );
};

const HomeBig = ({ lang }) => {
  return (
    <div className="home-big-container">
      <NewNavSection
        mediaLog={{ laptop: true }}
        lang={lang}
        special={"home"}
        noLogo
      />

      <div className="top-container">
        <h2 className="description-text">{textHomePage1[lang]}</h2>
        <LogoCircle />
      </div>

      <div id="description-big">{textHomePage3[lang]}</div>
      <section className="presentation-section">
        <PresBox
          head={textPres1[lang].head}
          body={textPres1[lang].body}
          image="/images/presentation1.jpg"
        />
        <PresBox
          head={textPres2[lang].head}
          body={textPres2[lang].body}
          image="/images/presentation2.jpg"
        />
        <PresBox
          head={textPres3[lang].head}
          body={textPres3[lang].body}
          image="/images/presentation3.jpg"
        />
        <PresBox
          head={textPres4[lang].head}
          body={textPres4[lang].body}
          image="/images/presentation4.jpg"
        />
        <PresBox
          head={textPres5[lang].head}
          body={textPres5[lang].body}
          image="/images/presentation5.jpg"
        />
      </section>

      <section className="mid-section">
        <div id="description-profi">
          {textHomePage2[lang]}{" "}
          <Link to="/profi">
            {
              {
                ro: "Click aici pentru mai multe informaţii",
                eng: "Click here to find out more",
              }[lang]
            }
          </Link>
        </div>
      </section>

      <MailCollector lang={lang} />

      <ContactSection lang={lang} />
    </div>
  );
};

export default HomeBig;
