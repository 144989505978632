import React, { useState } from "react";
// import NavSection from "../../subs/NavSection/NavSection"
import NewNavSection from "../../subs/NewNavSection/NewNavSection";

import { textBlogPosts } from "../../../Texts";

import "./Blog.css";

const Blog = ({ lang, mediaLog }) => {
  return (
    <div className="blog-container">
      <NewNavSection mediaLog={mediaLog} lang={lang} special={"blog"} />
      {textBlogPosts.map((blogPost, index) => (
        <BlogPost key={index} blog={blogPost} lang={lang} index={index} />
      ))}
    </div>
  );
};

const BlogPost = props => {
  const [show, setShow] = useState(false);
  return (
    <div className="blog-post">
      <div className="top-container">
        <span className="index">{`POST#${props.index + 1}`}</span>
        <div style={{ flex: "1" }} />
        <span className="date">
          <i className="far fa-calendar-alt fa-sm"></i>
          {props.blog.date}
        </span>
      </div>
      <h1 className="header" onClick={() => setShow(prev => !prev)}>
        <button className="bttn-show">
          <i
            className={`fas ${show ? "fa-angle-up" : "fa-angle-down"} fa-lg`}
          ></i>
        </button>
        {props.blog.header[props.lang]}
      </h1>
      {show && <div className="body">{props.blog.body[props.lang]}</div>}
    </div>
  );
};

export default Blog;
