import React from "react";
import ReactDom from "react-dom";
import { Link } from "react-router-dom";
import LangSelector from "../LangSelector/LangSelector";
import LogoCircle from "../LogoCircle/LogoCircle";
import { ContactButtons } from "../ContactSection/ContactSection";
import { textNavSection } from "../../../Texts.js";
import Backdrop from "../Backdrop/Backdrop";

import "./NewNavSection.css";

class NewNavSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      laptop: props.mediaLog.laptop,
      tablet: props.mediaLog.tablet,
      color: props.special,
      noLogo: props.noLogo,
      lang: props.lang,
      show: false,
    };
  }

  // Spagetti
  render() {
    let sliderClose = () => {
      this.setState({ show: false });
    };

    let sliderOpen = () => {
      this.setState({ show: true });
    };

    return this.state.laptop ? (
      <nav>
        <NavSectionItems
          color={this.state.color}
          lang={this.state.lang}
          withLangSelection
          laptop
        />
      </nav>
    ) : (
      <nav>
        <Backdrop show={this.state.show} click={sliderClose} />
        {ReactDom.createPortal(
          <div
            className="slider-white"
            style={{
              transform: this.state.show ? null : "translateX(100%)",
            }}
          >
            <i
              className="far fa-times-circle close-bttn"
              onClick={sliderClose}
            ></i>
            <NavSectionItems color={this.state.color} lang={this.state.lang} />
            <ContactButtons />
          </div>,
          document.getElementById("slider-portal")
        )}
        <div className="toolbar-items">
          {!this.state.noLogo && (
            <LogoCircle size={this.state.tablet ? "90px" : "65px"} />
          )}
          {!this.state.noLogo && <div style={{ flex: "0.1" }}></div>}
          <LangSelector lang={this.state.lang} />
          <div style={{ flex: "1" }}></div>
          <div className="menu-bttn" onClick={sliderOpen}>
            {{ ro: "Meniu", eng: "Menu" }[this.state.lang]}
            <i className="fas fa-bars fa-lg"></i>
          </div>
        </div>
      </nav>
    );
  }
}

const NavSectionItems = ({
  laptop,
  color = "none",
  lang,
  withLangSelection,
}) => {
  return (
    <ul className="nav-section-items-container">
      {laptop && color !== "home" && color !== "contact" && (
        <LogoCircle size="65px" />
      )}
      {laptop && color !== "home" && color !== "contact" && (
        <div style={{ flex: "0.2" }}></div>
      )}
      {laptop && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "800px",
          }}
        >
          {textNavSection[lang].map(elem => {
            return (
              <li
                key={elem.label}
                className={color === elem.label ? "link-highlight" : ""}
              >
                <Link to={elem.dir}>{elem.innerHtml}</Link>
                <i className={elem.icon + " fa-sm"}></i>
              </li>
            );
          })}
        </div>
      )}
      {!laptop &&
        textNavSection[lang].map(elem => {
          return (
            <li
              key={elem.label}
              className={color === elem.label ? "link-highlight" : ""}
            >
              <Link to={elem.dir}>{elem.innerHtml}</Link>
              <i className={elem.icon + " fa-sm"}></i>
            </li>
          );
        })}
      {withLangSelection && <div style={{ flex: "1" }}></div>}
      {withLangSelection && <LangSelector lang={lang} />}
    </ul>
  );
};

export default NewNavSection;
