import { useState } from "react";

const useForm = (initialVals, required) => {
  const [vals, setVals] = useState(initialVals);

  const updateVals = e => {
    const valName = e.target.name;
    const newVal =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    setVals({
      ...vals,
      [valName]: newVal,
    });
  };

  return [{ ...vals, required }, updateVals];
};

export const countRequiredLeft = vals => {
  let res = 0;
  vals.required.forEach(field => {
    if (!vals[field]) {
      res++;
    }
  });
  return res;
};

export default useForm;
