import React from "react";
import ReactDom from "react-dom";

const Backdrop = (props) => {
  return ReactDom.createPortal(
    props.show && (
      <div
        onClick={props.click}
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0,0,0,0.8)",
          zIndex: "100",
          cursor: "pointer",
        }}
      ></div>
    ),
    document.getElementById("backdrop-portal")
  );
};

export default Backdrop;
