import React from "react";

export const boldify = str => {
  const splitAt = (s, sub) => {
    const ind = s.indexOf(sub);
    if (ind === -1) {
      return [s, ""];
    }
    return [s.slice(0, ind), s.slice(ind + sub.length, s.length)];
  };

  const children = [];
  let splits = splitAt(str, "{{");

  while (splits[1] !== "") {
    children.push(splits[0]);
    splits = splitAt(splits[1], "}}");
    children.push(<span style={{ fontWeight: "bold" }}>{splits[0]}</span>);
    splits = splitAt(splits[1], "{{");
  }

  children.push(splits[0]);
  return <span>{children}</span>;
};

export default boldify;
