import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import NewNacSection from "../../subs/NewNavSection/NewNavSection";
import ContactSection, {
  ContactButtons,
} from "../../subs/ContactSection/ContactSection";
import ImageGallery from "./ImageGallery";
import ImageViewer from "./ImageViewer";
import {
  textProductsList,
  textReturnToProductsButton,
  textInterestedInProduct,
  textFindMonthlyPayment,
} from "../../../Texts";
import { calcPayment, BACKEND_API_URL } from "../../../Utils";
import boldify from "../../../Boldify";

import "./ProductPresentation.css";

const ProductPresentation = ({ match, lang, mediaLog }) => {
  const label = match.match.params.label;

  let product;
  textProductsList.forEach(p => {
    if (p.label === label) {
      product = p;
    }
  });

  const [index, setIndex] = useState(0);
  let increment = () => {
    setIndex(prevIndex =>
      prevIndex < product.images.length - 1 ? prevIndex + 1 : 0
    );
  };
  let decrement = () => {
    setIndex(prevIndex =>
      prevIndex > 0 ? prevIndex - 1 : product.images.length - 1
    );
  };
  let smallImgClick = imgName => {
    setIndex(product.images.indexOf(imgName));
  };

  const [show, setShow] = useState(false);
  let openViewer = () => {
    setShow(true);
  };
  let closeViewer = () => {
    setShow(false);
  };

  const [exchangeData, setExchangeData] = useState(null);
  useEffect(() => {
    fetch(`${BACKEND_API_URL}/currency`)
      .then(res => res.json())
      .then(data => setExchangeData(data))
      .catch(err => console.log("Something went wrong"));
  }, []);
  const [tenor, setTenor] = useState(product.price ? "12" : null);
  const [monthly, total] = calcPayment(
    tenor,
    product.price,
    exchangeData && exchangeData.exchangeRate
  );
  const monthWord = { ro: " luni", eng: " months" }[lang];
  const currencyWord = { ro: "Lei", eng: "RON" }[lang];

  return (
    <div className="product-presentation-container">
      <NewNacSection mediaLog={mediaLog} lang={lang} special="products" />
      <div className="top-container">
        <ReturnToProductsButton lang={lang} />
        <OrderProductButton lang={lang} label={label} />
      </div>

      <div className="content-container">
        {show && (
          <ImageViewer
            imgs={product.images}
            close={closeViewer}
            index={index}
            decrement={decrement}
            increment={increment}
            smallImgClick={smallImgClick}
            mediaLog={mediaLog}
          />
        )}
        <ImageGallery
          imgs={product.images}
          enlarge={openViewer}
          index={index}
          decrement={decrement}
          increment={increment}
          smallImgClick={smallImgClick}
        />
        <div className="text-container">
          <div
            style={{
              paddingBottom: "15px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {" "}
            <h1 className="product-name">{product.name[lang]}</h1>
            {product.price && (
              <p className="product-price">
                {product.price}
                <span className="tva">
                  {"    " + { ro: "TVA inclus", eng: "VAT incl" }[lang]}
                </span>
              </p>
            )}
          </div>
          {product.isDevice && (
            <div className="monthly-payment-container">
              {textFindMonthlyPayment(product.name[lang])[lang]}
              <div className="select-container">
                <div
                  style={{
                    fontSize: ".67em",
                    lineHeight: "16px",
                    textAlign: "center",
                    marginBottom: "3px",
                  }}
                >
                  {
                    {
                      ro: "12 sau 24 rate\nfără dobândă",
                      eng: "12 or 24 months\ninterest free",
                    }[lang]
                  }
                </div>
                <select value={tenor} onChange={e => setTenor(e.target.value)}>
                  <option value="12">12{monthWord}</option>
                  <option value="24">24{monthWord}</option>
                  <option value="36">36{monthWord}</option>
                  <option value="48">48{monthWord}</option>
                  <option value="60">60{monthWord}</option>
                </select>
              </div>
              <div className="calc-res">
                <span style={{ fontWeight: "bold" }}>
                  {{ ro: "Rata lunară:", eng: "Monthly payment:" }[lang] +
                    ` ${monthly} ${currencyWord}`}
                </span>
                <br />
                <span style={{ fontSize: ".75em", lineHeight: "18px" }}>
                  {{ ro: "Plata totală:", eng: "Total payment:" }[lang] +
                    ` ${total} ${currencyWord}\n`}
                  {exchangeData &&
                    {
                      ro: `Curs valutar BNR ${exchangeData.exchangeRate} Lei/EUR,${exchangeData.date}`,
                      eng: `BNR exchange rate ${exchangeData.exchangeRate} RON/EUR,${exchangeData.date}`,
                    }[lang]}
                </span>
              </div>
            </div>
          )}
          <p className="product-description">
            {boldify(product.longDescription[lang])}
          </p>
        </div>
      </div>
      {product.label === "casa_total" ? (
        <div className="casa-total">
          <div className="extra-presentation">
            <div>{boldify(product.extraDescription.left[lang])}</div>
            <div>{boldify(product.extraDescription.right[lang])}</div>
          </div>
        </div>
      ) : (
        <div className="extra-presentation">
          {product.extraDescription && boldify(product.extraDescription[lang])}
          {product.individuals && (
            <div className="individuals">
              {product.individuals.map(individual => (
                <IndividualProduct
                  name={individual.name[lang]}
                  for={individual.for[lang]}
                  price={individual.price}
                  image={individual.image}
                />
              ))}
            </div>
          )}
          {product.properties && (
            <ul className="properties-table">
              <span className="pre-table">
                {{
                  ro: "Specificaţii",
                  eng: "Specifications",
                }[lang] + "\n"}
              </span>
              {product.properties[lang].map((pair, index) => (
                <li
                  className="table-row"
                  key={index}
                  style={{ backgroundColor: index % 2 ? null : "white" }}
                >
                  <div className="key">{pair[0]}</div>
                  <div className="value">{pair[1]}</div>
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
      <div className="bottom-container">
        <div style={{ marginBottom: "40px" }}>
          <OrderProductButton lang={lang} label={label} />
        </div>
        {textInterestedInProduct[lang]}
        {mediaLog.laptop ? <ContactSection /> : <ContactButtons />}
      </div>
    </div>
  );
};

const ReturnToProductsButton = ({ lang }) => {
  return (
    <Link to="/produse" className="top-bttn back-bttn">
      <i className="fas fa-arrow-left"></i>
      {textReturnToProductsButton[lang]}
    </Link>
  );
};

const OrderProductButton = ({ lang, label }) => {
  return (
    <Link to={`/comanda/${label}`} className="top-bttn order-bttn">
      {{ ro: "Comandă acum", eng: "Order now" }[lang]}
      <i className="fas fa-arrow-right"></i>
    </Link>
  );
};

const IndividualProduct = props => {
  return (
    <div className="individual-product-container">
      <div className="top">
        <p className="name">{props.name}</p>
        <p style={{ fontWeight: "normal", color: "var(--color-grey)" }}>
          {props.for}
        </p>
        <span className="price">{props.price}</span>
      </div>
      <img src={props.image} alt="image_not_found" />
    </div>
  );
};

export default ProductPresentation;
