import React from "react";
import {
  textAboutPageTitle,
  textAboutPageContent,
  textAboutPageSignature,
} from "../../../Texts.js";
// import NavSection from "../../subs/NavSection/NavSection"
import NewNavSection from "../../subs/NewNavSection/NewNavSection";

import "./About.css";

const About = ({ lang, mediaLog }) => {
  return (
    <div className="about-container">
      <NewNavSection mediaLog={mediaLog} lang={lang} special={"about"} />

      <div
        className="iqua-text content-container"
        style={{ display: !mediaLog.laptop && "block" }}
      >
        <div
          className="left-container"
          style={{ backgroundImage: !mediaLog.laptop && "none" }}
        >
          <h1>{textAboutPageTitle[lang]}</h1>
        </div>

        <div className="right-container">
          {textAboutPageContent[lang].map((line, i) => (
            <p key={i}>{line}</p>
          ))}
          <h2 className="signature">{textAboutPageSignature[lang]}</h2>
        </div>
      </div>
    </div>
  );
};

export default About;
