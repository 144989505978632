import React from "react";
import NewNavSection from "../../subs/NewNavSection/NewNavSection";
import ProductBox from "./ProductBox/ProductBox";

import { textProductsList } from "../../../Texts.js";

import "./Products.css";
const Products = ({ lang, mediaLog }) => {
  return (
    <div className="products-page-container">
      <NewNavSection mediaLog={mediaLog} lang={lang} special={"products"} />
      <div className="products-page-content-container">
        {textProductsList.map((product, i) => {
          return (
            <ProductBox
              product={product}
              lang={lang}
              mediaLog={mediaLog}
              key={product.label}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Products;
