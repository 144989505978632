// JS File with random utility functions
import { textProductsList } from "./Texts";

export const BACKEND_API_URL = "https://iqua-mail-list.herokuapp.com";

export const findProductByLabel = label => {
  for (const prod of textProductsList) {
    if (prod.label === label) {
      return prod;
    }
  }
  return null;
};

// Credits to https://gist.github.com/maarten00/23400873d51bf2ec4eeb
function pmt(
  rate_per_period,
  number_of_payments,
  present_value,
  future_value,
  type
) {
  future_value = typeof future_value !== "undefined" ? future_value : 0;
  type = typeof type !== "undefined" ? type : 0;

  if (rate_per_period !== 0.0) {
    // Interest rate exists
    var q = Math.pow(1 + rate_per_period, number_of_payments);
    return (
      -(rate_per_period * (future_value + q * present_value)) /
      ((-1 + q) * (1 + rate_per_period * type))
    );
  } else if (number_of_payments !== 0.0) {
    // No interest rate, but number of payments exists
    return -(future_value + present_value) / number_of_payments;
  }

  return 0;
}

export const calcPayment = (tenor, price, exchangeRate) => {
  if (!price) {
    return [null, null];
  }

  const nMonths = parseFloat(tenor);
  const interest = nMonths <= 24 ? 0 : 18.0 / 100;
  const adminFee = nMonths <= 24 ? 0 : 170;
  exchangeRate = exchangeRate || 4.9;
  const priceRon = parseFloat(price.replace(".", "")) * exchangeRate;
  const monthly = pmt(interest / 12, nMonths, -(priceRon + adminFee));
  return [Math.round(monthly), Math.round(monthly * nMonths)];
};
