import React, { useState } from "react";
import { Link } from "react-router-dom";

import NewNavSection from "../../subs/NewNavSection/NewNavSection";
import {
  FORM_FIELD_TITLES,
  textOrderPageIntro,
  textSuccessfullOrder,
  textRequiredFieldsLeft,
  textOverMessageCharLimit,
  textCannotEstablishConnection,
  textRedirectGDPR,
  textValsSmallerThan100,
} from "../../../Texts";
import { findProductByLabel, BACKEND_API_URL } from "../../../Utils";
import useForm, { countRequiredLeft } from "../../../hooks/useForm";

import "./OrderPage.css";

const OrderPage = ({ lang, mediaLog, match }) => {
  const product = findProductByLabel(match.match.params.label);

  const [vals, handleChange] = useForm(
    {
      name: "",
      email: "",
      phone: "",
      county: "",
      city: "",
      products: `${product ? product.name[lang] : ""},`,
      message: "",
      subscribe: false,
    },
    ["name", "email", "phone", "county", "city"]
  );

  const [status, setStatus] = useState({ code: STATUS_CODE.nothing });
  let formLogIcon, formLogText;
  switch (status.code) {
    case STATUS_CODE.loading:
      formLogIcon = <i className="fas fa-spinner fa-lg rotate"></i>;
      formLogText = "";
      break;
    case STATUS_CODE.success:
      formLogIcon = <i className="fas fa-check fa-lg"></i>;
      formLogText = textSuccessfullOrder[lang];
      break;
    case STATUS_CODE.failure:
      formLogIcon = <i className="fas fa-times fa-lg"></i>;
      formLogText = status.message[lang];
      break;
    default:
      formLogIcon = formLogText = null;
  }

  const handleSubmit = e => {
    setStatus({ code: STATUS_CODE.loading });

    const reqsLeft = countRequiredLeft(vals);
    if (reqsLeft !== 0) {
      setStatus({
        code: STATUS_CODE.failure,
        message: textRequiredFieldsLeft(reqsLeft),
      });
      return;
    }

    const messageDiff = vals.message.length - MESSAGE_CHAR_LIMIT;
    if (messageDiff > 0) {
      setStatus({
        code: STATUS_CODE.failure,
        message: textOverMessageCharLimit(messageDiff),
      });
      return;
    }

    for (let fieldName in vals) {
      const fieldVal = vals[fieldName];
      if (typeof fieldVal === "string" && fieldVal.length > 100) {
        setStatus({
          code: STATUS_CODE.failure,
          message: textValsSmallerThan100,
        });
        return;
      }
    }

    // TODO: add robot check

    // Client checks are done. Make request to server and wait for the response
    submitOrder(vals, setStatus, lang).catch(err => {
      // Fetch failed
      setStatus({
        code: STATUS_CODE.failure,
        message: textCannotEstablishConnection,
      });
    });
  };

  const titles = FORM_FIELD_TITLES[lang];
  return (
    <div className="order-page">
      <NewNavSection mediaLog={mediaLog} lang={lang} noLogo />
      <div className="form-container">
        {textOrderPageIntro[lang]}
        <br />
        <span style={{ fontSize: "0.8em" }}>
          {textRedirectGDPR[lang]}{" "}
          <Link to="/gdpr">{{ ro: "aici", eng: "here" }[lang]}</Link>
          <br />
          <span className="req-expl">
            {{ ro: "Câmp obligatoriu", eng: "Required field" }[lang]}
          </span>
        </span>
        <div className="input-wrapper required">
          <p>
            <i className="fas fa-user-edit fa-sm"></i>
            {FORM_FIELD_TITLES[lang].frmName}
          </p>
          <input
            name="name"
            className="user-input"
            value={vals.name}
            onChange={handleChange}
          />
        </div>
        <div className="input-wrapper required">
          <p>
            <i className="fas fa-envelope-open fa-sm"></i>
            {FORM_FIELD_TITLES[lang].frmEmail}
          </p>
          <input
            name="email"
            className="user-input"
            value={vals.email}
            onChange={handleChange}
          />
        </div>
        <div className="input-wrapper required">
          <p>
            <i className="fas fa-phone fa-sm"></i>
            {FORM_FIELD_TITLES[lang].frmPhone}
          </p>
          <input
            name="phone"
            className="user-input"
            value={vals.phone}
            onChange={handleChange}
          />
        </div>
        <div className="input-wrapper required">
          <p>
            <i className="fas fa-map-marker-alt fa-sm"></i>
            {FORM_FIELD_TITLES[lang].frmCounty}
          </p>
          <input
            className="user-input"
            name="county"
            value={vals.county}
            onChange={handleChange}
          />
        </div>
        <div className="input-wrapper required">
          <p>
            <i className="fas fa-map-marker-alt fa-sm"></i>
            {FORM_FIELD_TITLES[lang].frmCity}
          </p>
          <input
            className="user-input"
            name="city"
            value={vals.city}
            onChange={handleChange}
          />
        </div>
        <div className="input-wrapper">
          <p>
            <i className="fas fa-shopping-cart fa-sm"></i>
            {FORM_FIELD_TITLES[lang].frmProducts}
          </p>
          <input
            className="user-input"
            name="products"
            value={vals.products}
            onChange={handleChange}
          />
        </div>
        <div className="input-wrapper">
          <p>
            <i className="fas fa-feather fa-sm"></i>
            {FORM_FIELD_TITLES[lang].frmMessage}{" "}
            <span style={{ fontSize: "0.7em" }}>
              (max 400 {{ ro: "de caractere", eng: "characters" }[lang]})
            </span>
          </p>
          <textarea
            className="user-input"
            name="message"
            value={vals.message}
            onChange={handleChange}
          />
        </div>
        <div className="input-wrapper">
          {FORM_FIELD_TITLES[lang].frmSubscribe}
          <input
            className="user-input"
            name="subscribe"
            type="checkbox"
            value={vals.subscribe}
            onChange={handleChange}
          />
        </div>
        <button className="bttn-submit-order" onClick={handleSubmit}>
          <span>{{ ro: "Trimite", eng: "Submit" }[lang]}</span>
          <i className="fas fa-paper-plane fa-sm"></i>
        </button>
        {status !== STATUS_CODE.nothing && (
          <span className="form-log">
            {formLogIcon}
            {formLogText}
          </span>
        )}
      </div>
    </div>
  );
};

const MESSAGE_CHAR_LIMIT = 400;
const STATUS_CODE = {
  nothing: 0,
  loading: 1,
  success: 2,
  failure: 3,
};

const submitOrder = async (order, setStatus, lang) => {
  const res = await fetch(`${BACKEND_API_URL}/order?lang=${lang}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(order),
  });
  switch (res.status) {
    case 406:
      const data = await res.json();
      console.log(data);
      setStatus({ code: STATUS_CODE.failure, message: data.error });
      break;
    case 200:
      setStatus({ code: STATUS_CODE.success });
      break;
    case 500:
      setStatus({
        code: STATUS_CODE.failure,
        message: {
          ro:
            "Ne cerem scuze, in acest moment nu se poate realiza inregistrarea comenzii datorita unor probleme cu serverele noastre. Va rugam sa vizitati sectiunea Contact pentru a lua legatura cu noi.",
          eng:
            "At the moment no order can be registered due to an internal problem with our servers. Please visit the Contact section to get in touch with us. We are sorry for the inconvenience",
        },
      });
      break;
    default:
      console.assert(false); // This statement should never be reached
      break;
  }
};

export default OrderPage;
