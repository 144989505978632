import React from "react";

import NewNavSection from "../../subs/NewNavSection/NewNavSection";

import "./Profi.css";

import { textProfiPage } from "../../../Texts";

const Profi = props => {
  return (
    <div className="profi-container">
      <NewNavSection mediaLog={props.mediaLog} lang={props.lang} />

      <div className="content-container">
        {textProfiPage.map((profiObj, index) => {
          return (
            <div className="profi-pres" key={index}>
              <p style={{ padding: "10px 5px" }}>
                {profiObj.text ? profiObj.text[props.lang] : ""}
              </p>
              <img src={profiObj.image} alt="image_not_found" />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Profi;
