import React from "react";
import { Link } from "react-router-dom";

import "./RightsSection.css";

const RightsSection = props => {
  return (
    <div className={"rights-section-container" + (props.yes ? " recolor" : "")}>
      <span>
        {
          {
            ro: "Drepturi rezervate © 2020 GOA Services & Distribution s.r.l.",
            eng: "Copyright © 2020 GOA Services & Distribution s.r.l.",
          }[props.lang]
        }
      </span>

      <a href="https://anpc.ro/">ANPC</a>

      <Link to="/gdpr">GDPR</Link>
    </div>
  );
};

export default RightsSection;
