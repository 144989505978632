import React from "react";

import NewNavSection from "../../subs/NewNavSection/NewNavSection";

import { textGDPR } from "../../../Texts";
import boldify from "../../../Boldify";

const GDPR = props => {
  return (
    <div className="gdpr-container">
      <NewNavSection mediaLog={props.mediaLog} lang={props.lang} />
      <div
        className="content-container"
        style={{
          padding: "25px",
          fontFamily: "var(--font-primary)",
          fontSize: "var(--text-size-phone-medium)",
          color: "var(--color-dark-blue)",
          lineHeight: "25px",
        }}
      >
        {boldify(textGDPR[props.lang])}
      </div>
    </div>
  );
};

export default GDPR;
