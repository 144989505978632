import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import Langs from "./Languages"
import App from './App';
import './index.css';

let currLang = Langs.ro

// Re-render the entire app when changing the language
export const changeLang = lang => {
  currLang = lang
  ReactDOM.render(
    <React.StrictMode>
      <App lang={lang} />
    </React.StrictMode>,
    document.getElementById('root')
  );
}

ReactDOM.render(
  <React.StrictMode>
    <App lang={currLang} />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
