import React from "react";

import Langs, { LangFlags, NextLang } from "../../../Languages";
import { changeLang } from "../../../index";

import "./LangSelector.css";

const LangSelector = ({ lang }) => {
  const handleClick = lang => {
    changeLang(Langs[NextLang[lang]]);
  };

  if (false) {
    return (
      <div className="lang-selector-container">
        <i className="fas fa-angle-down fa-lg"></i>
        {lang.toUpperCase()}
      </div>
    );
  }

  return (
    <div className="lang-selector-container">
      <button onClick={() => handleClick(lang)}>
        <img
          src={LangFlags[NextLang[lang]].img}
          alt={LangFlags[NextLang[lang]].alt}
        />
      </button>
    </div>
  );
};

export default LangSelector;
