import React, { useState } from "react";
import ReactDom from "react-dom";
import Backdrop from "../../subs/Backdrop/Backdrop";

import "./ImageViewer.css";

const ImageViewer = props => {
  const [zoom, setZoom] = useState(true);
  const [bgPos, setBgPos] = useState(null);

  let zoomIn = event => {
    if (zoom) {
      event.persist();
      const width = event.target.offsetWidth;
      const height = event.target.offsetHeight;
      const mouseX = event.clientX;
      const mouseY = event.clientY;

      const bgPosX = (mouseX / width) * 100 - 41;
      const bgPosY = (mouseY / height) * 100 - 12;

      setBgPos(`${bgPosX}% ${bgPosY}%`);
    }
  };

  if (props.mediaLog.laptop) {
    return (
      <div>
        <Backdrop click={props.close} show />
        {ReactDom.createPortal(
          <div className="image-viewer-container">
            <button className="close-bttn">
              <i className="fas fa-times fa-lg" onClick={props.close}></i>
            </button>

            <div className="left-images">
              {props.imgs.map((image, i) => (
                <img
                  src={image}
                  alt="image_not_found"
                  key={i}
                  className={i === props.index ? "img-selected" : undefined}
                  onClick={() => props.smallImgClick(image)}
                />
              ))}
              <div className="ctrl-container">
                <button onClick={props.decrement} className="bttn-decrement">
                  <i className="fas fa-chevron-left fa-2x"></i>
                </button>
                <span className="image-index">{`${props.index + 1}/${
                  props.imgs.length
                }`}</span>
                <button onClick={props.increment} className="bttn-increment">
                  <i className="fas fa-chevron-right fa-2x"></i>
                </button>
              </div>
            </div>
            <div
              id={zoom ? "zoom-toggle" : null}
              className={`right-image`}
              style={{
                backgroundImage: `url('${props.imgs[props.index]}')`,
                backgroundPosition: bgPos,
              }}
              onMouseMove={zoomIn}
              onMouseOut={() => setBgPos(undefined)}
              onClick={() => setZoom(old => !old)}
            ></div>
          </div>,
          document.getElementById("image-viewer-portal")
        )}
      </div>
    );
  }

  return ReactDom.createPortal(
    <div className="image-viewer-container">
      <i
        className="far fa-times-circle close-bttn fa-lg"
        onClick={props.close}
      ></i>
      <img
        src={props.imgs[props.index]}
        alt="image_not_found"
        className="main-image"
      />
      <div className="ctrl-container">
        <button onClick={props.decrement} className="bttn-decrement">
          <i className="fas fa-chevron-left fa-2x"></i>
        </button>
        <button onClick={props.increment} className="bttn-increment">
          <i className="fas fa-chevron-right fa-2x"></i>
        </button>
      </div>
    </div>,
    document.getElementById("image-viewer-portal")
  );
};

export default ImageViewer;
