import React from "react";
import { Link } from "react-router-dom";
import "./ProductBox.css";

import { textFindOutMoreButton } from "../../../../Texts.js";
import boldify from "../../../../Boldify";

const ProductBox = ({ product, mediaLog, lang }) => {
  const noInterestImg = product.isDevice
    ? `images/interest-free-${lang}.png`
    : null;
  return (
    <Link
      to={"/produse/" + product.label}
      className="iqua-text-box product-box-container"
    >
      <img
        src={product.images[0]}
        alt="image_not_found"
        className="product-image"
      />
      <div className="product-text-container">
        <h2 className="name">{product.name[lang]}</h2>
        <p className="description">{boldify(product.shortDescription[lang])}</p>
        {product.price && (
          <h3 className="price">
            <span>
              {product.individuals && (
                <span className="from">
                  {{ ro: "de la ", eng: "from " }[lang] + "  "}
                </span>
              )}
              {product.price}
              <span className="tva">
                {"    " + { ro: "TVA inclus", eng: "VAT incl" }[lang]}
              </span>
            </span>
            {noInterestImg && (
              <img
                src={noInterestImg}
                alt="image_not_found"
                className="interest-free-img"
              />
            )}
          </h3>
        )}

        <FindOutMoreButton label={product.label} lang={lang} />
      </div>
      <img
        src="images/made-in-italy.png"
        className="made-in-italy"
        alt="Made in Italy"
      />
      <span className="status">{fromStatus(product.status, lang)}</span>
    </Link>
  );
};

const fromStatus = (status, lang) => {
  let res = { ro: "Stoc epuizat", eng: "Out of stock" };
  if (status === 1) {
    res = { ro: "În stoc", eng: "On stock" };
  }
  if (status === 2) {
    res = {
      ro: "Livrare în 3-4 săptămâni",
      eng: "3-4 weeks delivery time",
    };
  }
  if (status === 3) {
    res = {
      ro: "Proiect la comandă",
      eng: "Custom project",
    };
  }

  return res[lang];
};

const FindOutMoreButton = ({ label, lang }) => {
  return (
    <div className="find-out-more-bttn">
      {textFindOutMoreButton[lang]}
      <i className="fas fa-arrow-right"></i>
    </div>
  );
};

export default ProductBox;
