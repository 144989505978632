import React from "react";

import NewNavSection from "../../subs/NewNavSection/NewNavSection";

import "./Support.css";

const Support = props => {
  const ytLink = "https://www.youtube.com/channel/UCAKGSmaWf64PH0nZlp1n0Yg";

  return (
    <div className="support-container">
      <NewNavSection
        mediaLog={props.mediaLog}
        lang={props.lang}
        special={"support"}
      />
      <div className="content">
        <div>
          <DownloadBttn
            file="/pdfs/iQUA_RO_systems_Declarație_Conformitate_producător.pdf"
            mediaLog={props.mediaLog}
          />
          Certificat de conformitate producător (pdf, RO)
        </div>

        <h3>
          {
            { ro: "Instrucţiuni de utilizare", eng: "Instruction manuals" }[
              props.lang
            ]
          }
        </h3>
        <div>
          <DownloadBttn
            file="/pdfs/iQUA_Supreme_Plus_User_Manual.pdf"
            mediaLog={props.mediaLog}
          />
          iQUA Supreme Plus (pdf, RO)
        </div>
        <div>
          <DownloadBttn
            file="/pdfs/iQUA_Complete_Box_User_Manual.pdf"
            mediaLog={props.mediaLog}
          />
          iQUA Complete Box (pdf, RO)
        </div>
        <div>
          <DownloadBttn
            file="/pdfs/iQUA_Smart_Box_User_Manual.pdf"
            mediaLog={props.mediaLog}
          />
          iQUA Smart Box (pdf, RO)
        </div>
        <div>
          <br />
          {
            {
              ro: (
                <span>
                  Vizitează{" "}
                  <a
                    href={ytLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "var(--color-light-blue)" }}
                  >
                    canalul iQUA Apă Curată
                  </a>{" "}
                  de pe YouTube pentru ghiduri de instalare şi de înlocuire a
                  filtrelor.
                </span>
              ),
              eng: (
                <span>
                  Vizit{" "}
                  <a
                    href={ytLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "var(--color-light-blue)" }}
                  >
                    the iQUA Apă Curată YouTube channel
                  </a>{" "}
                  for guides on installation and filter replacement.
                </span>
              ),
            }[props.lang]
          }
        </div>
      </div>
    </div>
  );
};

const DownloadBttn = props => (
  <a className="bttn-download" href={props.file} download>
    <i className="fas fa-file-download"></i>
  </a>
);

export default Support;
