import React, { useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Langs from "./Languages";

import "./App.css";

import Home from "./components/pages/Home/Home";
import About from "./components/pages/About/About";
import ApaCurata from "./components/pages/ApaCurata/ApaCurata";
import Products from "./components/pages/Products/Products";
import ProductPresentation from "./components/pages/ProductPresentation/ProductPresentation";
import Support from "./components/pages/Support/Support";
import Blog from "./components/pages/Blog/Blog";
import GDPR from "./components/pages/GDPR/GDPR";
import Contact from "./components/pages/Contact/Contact";
import Profi from "./components/pages/Profi/Profi";
import OrderPage from "./components/pages/OrderPage/OrderPage";

import RightsSection from "./components/subs/RightsSection/RightsSection";
import NewNavSection from "./components/subs/NewNavSection/NewNavSection";

const App = ({ lang = Langs.ro }) => {
  // For responsiveness
  let mediaLog = {
    phone: useMediaQuery("(max-width: 767px)"),
    tablet: useMediaQuery("(min-width: 768px) and (max-width: 1024px)"),
    laptop: useMediaQuery("(min-width: 1025px)"),
  };

  return (
    <div className="app-container">
      <BrowserRouter>
        <ScrollToTop>
          <Switch>
            <Route
              path="/"
              exact
              component={() => <Home lang={lang} mediaLog={mediaLog} />}
            />
            <Route
              path="/despre"
              component={() => <About lang={lang} mediaLog={mediaLog} />}
            />
            <Route
              path="/apaCurata"
              component={() => <ApaCurata lang={lang} mediaLog={mediaLog} />}
            />
            <Route
              path="/produse"
              exact
              component={() => <Products lang={lang} mediaLog={mediaLog} />}
            />
            <Route
              path="/produse/:label"
              component={match => (
                <ProductPresentation
                  lang={lang}
                  mediaLog={mediaLog}
                  match={match}
                />
              )}
            />
            <Route
              path="/comanda/:label"
              component={match => (
                <OrderPage lang={lang} mediaLog={mediaLog} match={match} />
              )}
            />
            <Route
              path="/suport"
              component={() => <Support lang={lang} mediaLog={mediaLog} />}
            />
            <Route
              path="/blog"
              component={() => <Blog lang={lang} mediaLog={mediaLog} />}
            />
            <Route
              path="/gdpr"
              component={() => <GDPR lang={lang} mediaLog={mediaLog} />}
            />
            <Route
              path="/contact"
              component={() => <Contact lang={lang} mediaLog={mediaLog} />}
            />
            <Route
              path="/profi"
              component={() => <Profi lang={lang} mediaLog={mediaLog} />}
            />
            <Route
              path="/unsub"
              component={() => <UnsubPage mediaLog={mediaLog} lang={lang} />}
            />
          </Switch>
        </ScrollToTop>
        <RightsSection lang={lang} yes />
      </BrowserRouter>
    </div>
  );
};

const UnsubPage = props => {
  const [email, setEmail] = useState("");
  const [info, setInfo] = useState({});

  const handleChange = event => {
    setEmail(event.target.value);
  };

  const handleSubmit = () => {
    setInfo({ fetching: "..." });
    fetch(`https://iqua-mail-list.herokuapp.com/remove?email=${email}`)
      .then(response => {
        response.json().then(data => {
          setInfo(data);
        });
      })
      .catch(error =>
        setInfo({
          error: {
            ro: "Dezabonarea nu se poate realilza",
            eng: "Something went wrong while connecting",
          },
        })
      );
  };

  const fromInfo = (info, lang) => {
    let toRender = "";
    if (info === {}) {
      return toRender;
    }

    if (info.fetching) {
      toRender = (
        <span>
          <span style={{ fontWeight: "bold" }}>Fetching</span>...
        </span>
      );
    } else if (info.error) {
      toRender = (
        <span>
          <span style={{ color: "#c80815", fontWeight: "bold" }}>Error:</span>{" "}
          {info.error[lang]}
        </span>
      );
    } else if (info.response) {
      toRender = (
        <span>
          <span style={{ color: "#089404", fontWeight: "bold" }}>Success:</span>{" "}
          {info.response[lang]}
        </span>
      );
    }

    return toRender;
  };

  return (
    <div>
      <NewNavSection mediaLog={props.mediaLog} lang={props.lang} />
      <div className="mail-collector">
        <div id="mail-submit">
          <input
            id="mail-input"
            type="text"
            placeholder="email"
            onChange={handleChange}
          />
          <button onClick={handleSubmit}>
            {{ ro: "Dezabonare", eng: "Unsubscribe" }[props.lang]}
          </button>
        </div>
        <p className="info-text">{fromInfo(info, props.lang)}</p>
      </div>
    </div>
  );
};

class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      console.log("test router", this.props);
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

// {
//             textProductsList.map((product, index) => {
//               return (
//                 <Route path={"/produse/" + product.label} component={
//                     () => <ProductPresentation lang={lang} product={product} mediaLog={mediaLog} />
//                 } key={index} />
//               )
//             })
//           }

export default App;
